import React from "react"
import { useSpring, animated } from "react-spring"
import Img from "gatsby-image"

import "./styles.scss"

const Vin = ({ data }) => {
  const imageprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(10%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 280,
  })
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(10%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  const linkprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(0%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 500,
  })
  return (
    <div className="vin">
      <animated.div style={textprops} className="vin-text">
        <h1>Vin</h1>
        <h2>Château La Grave - Duc Decazes</h2>
        <h3>Grand vin de Bordeaux - Bordeaux Supérieur.</h3>
        <p>
          Au Château La Grave, le travail de la vigne, les vendanges et la
          vinification sont entièrement traditionnels, mais avec du matériel
          moderne. La mise en bouteille ne s’effectue qu’après 20 mois d’élevage
          minutieux pour la cuvée normale, et 15 mois pour la cuvée réservée
          élevée en fûts de chêne.
        </p>
        <p>
          Le vin du Château La Grave associe alors la souplesse des Merlots à la
          sève puissante des Cabernets Franc et Sauvignon. Très représentatif de
          la race des grands vins du Libournais, Château La Grave se distingue
          par une robe pourpre veloutée, des arômes très concentrés de fruits
          rouges que soutient une note vanillée, une bouche souple, longue et
          racée. Son évolution, toujours excellente, lui confère une teinte
          pailletée et des arômes complexes de sous-bois.
        </p>
        <p>Pour plus d'informations, visitez le site :</p>
      </animated.div>
      <animated.a
        style={linkprops}
        href="https://www.ducdecazes.vin"
        target="_blank"
        rel="noopener noreferrer"
        className="vin-btn"
      >
        Château La Grave - Duc Decazes
      </animated.a>
      <animated.div style={imageprops} className="vin-image">
        <div className="vin-image-picture">
          <Img
            loading="auto"
            fluid={data.file.childImageSharp.fluid}
            alt="Bouteilles Château La Grave - Duc Decazes"
          />
        </div>
        <p>Bouteilles de Château La Grave - Duc Decazes</p>
      </animated.div>
    </div>
  )
}
export default Vin
